* {
  font-family: 'Cairo', sans-serif;
}

.main {
  margin: 40px 230px;
  font-weight: 700;
  position: relative;
  padding-bottom: 10px;
}

.main::after {
  content: '';
  position: absolute;
  top: 100%; /* Positioning the line just below the h1 */
  width: 22%; /* Ensure the line spans the entire width of the h1 */
  height: 8px;
  background-color: #B39330;
  border-radius: 5px;
}

.ltr::after {
  left: 0; /* Align to the left in LTR mode */
  width: 23%;
}

.rtl::after {
  right: 0; /* Align to the right in RTL mode */
}

.first {
  width: 70%;
  margin: 45px auto;
  border: 1px solid #B39330;
  padding: 30px;
  font-weight: 700;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  background-color: #fff;
  width: 100%;
}
label {
  margin-bottom: 8px;
}
.inputgroup {
  width: 100% !important;
  margin-bottom: 20px;
}

.addBtn {
  color: gray;
  padding: 10px 15px;
  border: 0;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  border: 0.5px solid gray;
  border-radius: 25px;
  transition: all 0.3s;
}
.table_container {
  width: 100%;
  overflow: auto;
}

.addBtn:hover {
  background-color: rgba(179, 147, 48, 1);
  color: white;
}

.payBtn {
  background-color: rgba(179, 147, 48, 1);
  color: white;
  padding: 10px 25px;
  margin-top: 45px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s;
}

 

@media screen and (max-width: 1200px) {
  .main {
    margin: 40px 150px;
  }
  .first {
    width: 80%;
    margin: 35px auto;
  }
}

@media screen and (max-width: 992px) {
  .main {
    margin: 40px 100px;
  }
  .first {
    width: 85%;
    margin: 30px auto;
  }

  .addBtn{
    margin: auto;



   }
   .cartPara{
    margin: auto;
    text-align: center;
   }
   .all{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 45px ;
   }
   .payBtn{
    margin-top: 0;
    margin: auto;
    
   }
}

.product_wrapper {
  padding: 12px;
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 24px;
  border-radius: 0;
  width: 100%;
}

.product_wrapper h4 {
  font-size: 18px;
}
.product_wrapper p {
  margin: 0;
  font-size: 14px;
}

.product_wrapper .text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 12px;
}

.product_wrapper .desc {
  padding: 8px;
  background: #ebebeb;
  color: #4e4e4e;
}
@media screen and (max-width: 767.98px) {
  .main {
    margin: 40px 50px;
  }
  /* .first {
    width: 90%;
    margin: 25px auto;
    padding: 25px;
  } */
   .addBtn{
    margin: auto;

   }
   .all{
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    padding: 20px 45px ;
   }
   .payBtn{
    margin-top: 0;
    margin: auto;
   }
}
table {
  width: 100%;
  white-space: nowrap;
  border-spacing: 2px 2px;
}

.table_wrapper {
  box-shadow: rgba(145, 158, 171, 0.4) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  border-radius: 1.25rem;
  padding: 1.5rem;
  padding-bottom: .75rem;
}

.table_container {
  width: 100%;
  max-width: 100%;
  overflow: auto;
  padding-bottom: .75rem;
  scrollbar-color: var(--main-color) var(--second-color);
  scrollbar-width: thin;
}

  .table_container::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: var(--second-color);
  }

  .table_container::-webkit-scrollbar {
      width: 6px;
      height: 7px;
      border-radius: 10px;
      background-color: var(--second-color);
      border-radius: 10px;
  }

  .table_container::-webkit-scrollbar-thumb {
      background-color: var(--main-color);
      border-radius: 10px;
  }


th {
  font-size: 16px;
  text-align: right;
  color: #B39330;
  padding: .8rem;
  border: none;
  border-bottom: 1px solid #5c5c5c1f;
}

 td {
  font-size: 16px;
  padding: 16px;
  font-weight: 400;
}

tr:not(:last-child) td {
  border-bottom: 1px solid #7070701f;

}

.details_wrapper {
  width: 85%;
  margin: 16px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap:4px;
}
.inputGroup input {
  background-color: #fff;
  color: rgb(32, 32, 32);
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  box-shadow: rgba(0, 0, 0, 0.025) 0px 4px 12px;
}
@media screen and (max-width: 576px) {
  .main {
    margin: 20px 20px;
  }
  .first {
    width: 95%;
    margin: 20px auto;
    padding: 20px;
  }
  .main::after {
    width: 130px;
  }
 
}


@media (max-width: 757.98px) {
  .first {
    max-width: 90%;
  }
  .details_wrapper {
    grid-template-columns: 1fr;
    width: 90%;
  }
}