.profile {
    width: 80%;
    border: 1px solid #B39330;
    padding: 30px;
    font-weight: 700;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin:  25px auto;

  }

    
  .main {
    margin: 40px 230px;
    font-weight: 700;
    position: relative;
    padding-bottom: 10px;
  }
  
  .main::after {
    content: '';
    position: absolute;
    top: 100%; /* Positioning the line just below the h1 */
    width: 25%; /* Ensure the line spans the entire width of the h1 */
    height: 8px;
    background-color: #B39330;
    border-radius: 5px;
  
  }
  .ltr::after {
    left: 0; /* Align to the left in LTR mode */
    width: 12%;
  }
  
  .rtl::after {
    right: 0; /* Align to the right in RTL mode */
  }
  .theImage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 25px;
    text-align: center;
    width: 150px; /* Ensure the container is a square */
    height: 150px; /* Ensure the container is a square */
    border-radius: 50%;
  }
  .img{
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container */
    border-radius: 50%; /* Make the image circular */

  }

  @media (max-width: 768px) {
    .main {
      margin: 20px 50px;
      font-size: 24px;
    }
  
   
  }
  
  @media (max-width: 576px) {
    .main {
      margin: 20px 20px;
      font-size: 20px;
    }
  
   
  }