.main {
    margin: 40px 230px;
    font-weight: 700;
    position: relative;
    padding-bottom: 10px;
  }
  
  .main::after {
    content: '';
    position: absolute;
    top: 100%; /* Positioning the line just below the h1 */
    width: 8%; /* Ensure the line spans the entire width of the h1 */
    height: 8px;
    background-color: #B39330;
    border-radius: 5px;
  }
  
  .ltr::after {
    left: 0; /* Align to the left in LTR mode */
    width: 5%;
  }
  
  .rtl::after {
    right: 0; /* Align to the right in RTL mode */
  }

  .first {
    width: 70%;
    margin: 45px auto;
    border: 1px solid #B39330;
    padding: 30px;
    font-weight: 700;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    background-color: #fff;
  }

  .firstPara {
    display: flex;
    width: 100%;
    font-weight: 800;
    line-height: 1.99;
    margin: 15px auto;
  }

  .theInput{
    width: 75%;
    padding: 15px;
    margin: auto;

  }

  .problemBtn{
    background-color: rgba(179, 147, 48, 1);
    color: white;
    padding: 10px 15px;
    border: 0;
    border-radius: 8px;
    text-decoration: none;
    display: inline-block;
    width: 40%;
    margin-top: 20px;
    cursor: pointer;
  }