*{
    font-family: 'Cairo';
  
  }
  .main {
      margin: 30px 60px;
      font-weight: 700;
      position: relative;
      padding-bottom: 10px;
  }
  
  .main::after {
      content: '';
      position: absolute;
      top: 100%; /* Positioning the line just below the h1 */
      width: 18%; /* Ensure the line spans the entire width of the h1 */
      height: 8px;
      background-color: #B39330;
      border-radius: 5px;
  }
  
  .ltr::after {
      left: 0; /* Align to the left in LTR mode */
      width: 14%;
  }
  
  .rtl::after {
      right: 0; /* Align to the right in RTL mode */
  }
  table {
    width: 100%;
    white-space: nowrap;
    border-spacing: 2px 2px;
  }
  
  .table_wrapper {
    box-shadow: rgba(145, 158, 171, 0.4) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    border-radius: 1.25rem;
    padding: 1.5rem;
    padding-bottom: .75rem;
  }
  
  .table_container {
    width: 100%;
    max-width: 100%;
    overflow: auto;
    padding-bottom: .75rem;
    scrollbar-color: var(--main-color) var(--second-color);
    scrollbar-width: thin;
  }
  
    .table_container::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: var(--second-color);
    }
  
    .table_container::-webkit-scrollbar {
        width: 6px;
        height: 7px;
        border-radius: 10px;
        background-color: var(--second-color);
        border-radius: 10px;
    }
  
    .table_container::-webkit-scrollbar-thumb {
        background-color: var(--main-color);
        border-radius: 10px;
    }
  
  
  th {
    font-size: 16px;
    text-align: right;
    color: #B39330;
    padding: .8rem;
    border: none;
    border-bottom: 1px solid #5c5c5c1f;
  }
  
   td {
    font-size: 16px;
    padding: 16px;
    font-weight: 400;
  }
  
  tr:not(:last-child) td {
    border-bottom: 1px solid #7070701f;
  
  }
  
  .details_wrapper {
    width: 85%;
    margin: 16px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }
  
  .upload {
      margin: 40px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
  }
  
  
.fileInput{
    width: 70% !important;
    border: 1px solid #000000 !important;
    padding: 15px !important;
    border-radius: 16px !important;
}
  
.fileInput::placeholder{
    color: #717070 !important;
}
.notFound{
    color: #717070;
}
.reserveBtn{
      background-color: rgba(179, 147, 48, 1);
      color: white;
      padding: 10px 15px;
      border: 0;
      border-radius: 8px;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items:center;
      width: 40%;
      margin: auto;
      margin-top: 0px;
      margin-bottom: 25px;
  }

  @media screen and (max-width: 576px) {
    .main::after{
        width: 150px;
    }
 
  }

  .first {
    width: 70%;
    margin: 45px auto;
    border: 1px solid #B39330;
    padding: 30px;
    font-weight: 700;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    background-color: #fff;
    width: 100%;
  }
  @media screen and (max-width: 576px) {
    .main {
      margin: 20px 20px;
    }
    .first {
      width: 95%;
      margin: 20px auto;
      padding: 20px;
    }
    .main::after {
      width: 130px;
    }
   
  }
  
  
  @media (max-width: 757.98px) {
    .first {
      max-width: 90%;
    }
    .details_wrapper {
      grid-template-columns: 1fr;
      width: 90%;
    }
  }  