* {
    font-family: 'Cairo', sans-serif;
  }
  
  .main {
    margin: 40px 230px;
    font-weight: 700;
    position: relative;
    padding-bottom: 10px;
  }
  
  .main::after {
    content: '';
    position: absolute;
    top: 100%; /* Positioning the line just below the h1 */
    width: 13%; /* Ensure the line spans the entire width of the h1 */
    height: 8px;
    background-color: #B39330;
    border-radius: 5px;
  }
  
  .ltr::after {
    left: 0; /* Align to the left in LTR mode */
    width: 12%;
  }
  
  .rtl::after {
    right: 0; /* Align to the right in RTL mode */
  }
  
  .first {
    width: 40%;
    margin: auto;
    border: 1px solid #B39330;
    padding: 30px;
    font-weight: 700;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .uploadPdf {
    background-color: rgba(179, 147, 48, 1);
    color: white;
    padding: 10px 15px;
    border: 0.5px solid rgba(179, 147, 48, 1);
    border-radius: 8px;
    text-decoration: none;
    margin-top: 20px;
  }
  
  @media (max-width: 1200px) {
    .main {
      margin: 40px 150px;
    }
  }
  
  @media (max-width: 992px) {
    .main {
      margin: 40px 100px;
    }
    
    .first {
      width: 60%;
    }
  }
  
  @media (max-width: 768px) {
    .main {
      margin: 20px 50px;
      font-size: 24px;
    }
  
    .first {
      width: 80%;
      padding: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .main {
      margin: 20px 20px;
      font-size: 20px;
    }
  
    .first {
      width: 90%;
      padding: 15px;
    }
  
    .uploadPdf {
      padding: 8px 12px;
      font-size: 14px;
    }
  }
  
  .Baqat{
    margin: 30px 60px;
    width:auto;
  }
  .blooadPhoto{
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    height: 250px;
    object-fit: cover;
  }
  .main::after{
      content: '';
      position: absolute;
      top: 60px;
      left: 45%;
      width:150px ;
      height: 8px;
      background-color: #B39330;
      border-radius: 5px;
  }
  .servicesCards{
      background-color: #FFFFFF;
      border: 0.5px solid #B39330;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 3px 1px rgba(0, 0, 0, 0.25);
      height: 100%;
  }
  
  .buttonCart{
      background-color: white;
      color: rgba(179, 147, 48, 1);
      padding: 10px 15px;
      border: 0.5px solid  rgba(179, 147, 48, 1);
      border-radius: 8px;
      transition: all 1s;
      text-decoration: none;
  
  }
  .buttonCart:hover{
      background-color: rgba(179, 147, 48, 1);
      color: white;
  }
  
  
  .input-group {
      position: relative;
      width: 100%; /* Ensure the input group takes full width */
    }
    
    .input-group-text {
      background-color: #fff; /* Background color for the icon container */
      border: none; /* Remove default border */
    }
    
    .input-group-text i {
      color: #555; /* Icon color */
      cursor: pointer; /* Cursor style */
      padding: 0 10px; /* Adjust padding as needed */
    }
  
  
  
    @media (max-width: 895px) {
  
      .main::after{
        content: '';
        position: absolute;
        top: 60px;
        left: 100px;
        right: 100px;
        width:210px ;
        height: 8px;
        background-color: #B39330;
        border-radius: 5px;
    }
  
    }