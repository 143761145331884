* {
    font-family: 'Cairo';
  }
  
  .main {
    margin: 30px 60px;
    font-weight: 700;
    position: relative;
    padding-bottom: 10px;
  }
  
  .main::after {
    content: '';
    position: absolute;
    top: 100%;
    width: 13%;
    height: 8px;
    background-color: #B39330;
    border-radius: 5px;
  }
  
  .ltr::after {
    left: 0;
    width: 27%;
  }
  
  .rtl::after {
    right: 0;
  }
  
  .upload {
    width: 80%;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    border: #B39330 1px solid;
    border-radius: 20px;
  }
  
  .inputgroup {
    width: 50% !important;
    margin-bottom: 20px;
  }
  
  .lableOpt {
    color: black !important;
    margin: 15px 0;
    font-weight: 700;
  }
  
  .theServe {
    color: black !important;
    margin: 15px 0;
    font-weight: 700;
  }
  
  .opt {
    font-weight: 700 !important;
  }
  
  .serveBtnNow {
    background-color: rgba(179, 147, 48, 1);
    color: white;
    padding: 10px 15px;
    border: 0;
    border-radius: 8px;
    text-decoration: none;
    display: inline-block;
    width: 25%;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .alignRight {
    text-align: right;
  }
  
  .alignLeft {
    text-align: left;
  }
  
  .personalInfoWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10px;
  }
  
  .updateBtn {
    background-color: white;
    color: black;
    padding: 10px 15px;
    margin: 15px;
    border: 1px solid #B39330;
    border-radius: 8px;
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
    cursor: pointer;
    position: absolute;
    top:-5px;
    transition: all 1s;
}

.updateBtn:hover{
    background-color:#B39330 ;
    color: white;

}

.select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  font-size: 16px;
}
  .personalInfoDisplay {
    width: 100%;
    border: 1px solid #ccc;
    height: 100px;
    padding: 15px;
    border-radius: 8px;
    white-space: pre-wrap; /* This preserves whitespace and new lines */
  }
  @media (max-width: 768px) {
    .upload {
      width: 90%;
    }

    .inputgroup {
      width: 100% !important;
    }
  
    .serveBtnNow {
      width: 50%;
    }
  }
  
  @media (max-width: 576px) {
    .main {
      margin: 20px 30px;
    }
  
    .serveBtnNow {
      width: 100%;
    }
  }

  @media (max-width: 895px) {


  .main::after {
    content: '';
    position: absolute;
    top: 100%;
    width: 140px;
    height: 8px;
    background-color: #B39330;
    border-radius: 5px;
  }
  
  }