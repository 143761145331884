* {
    font-family: 'Cairo', sans-serif;
  }
  
  .main {
    margin: 40px 230px;
    font-weight: 700;
    position: relative;
    padding-bottom: 10px;
  }
  
  .main::after {
    content: '';
    position: absolute;
    top: 100%; /* Positioning the line just below the h1 */
    width: 22%; /* Ensure the line spans the entire width of the h1 */
    height: 8px;
    background-color: #B39330;
    border-radius: 5px;
  }
  
  .ltr::after {
    left: 0; /* Align to the left in LTR mode */
    width: 23%;
  }
  
  .rtl::after {
    right: 0; /* Align to the right in RTL mode */
  }
  
  .first {
    width: 70%;
    margin: 45px auto;
    border: 1px solid #B39330;
    padding: 30px;
    font-weight: 700;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    background-color: #fff;
  }
  
  .addBtn {
    color: gray;
    padding: 10px 15px;
    border: 0;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    border: 0.5px solid gray;
    border-radius: 25px;
    transition: all 0.3s;
  }
  
  .addBtn:hover {
    background-color: rgba(179, 147, 48, 1);
    color: white;
  }
  
  .payBtn {
    background-color: rgba(179, 147, 48, 1);
    color: white;
    padding: 10px 25px;
    margin-top: 45px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s;
  }
  
   
  
  @media screen and (max-width: 1200px) {
    .main {
      margin: 40px 150px;
    }
    .first {
      width: 80%;
      margin: 35px auto;
    }
  }
  
  @media screen and (max-width: 992px) {
    .main {
      margin: 40px 100px;
    }
    .first {
      width: 85%;
      margin: 30px auto;
    }

    .addBtn{
      margin: auto;



     }
     .cartPara{
      margin: auto;
      text-align: center;
     }
     .all{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 45px ;
     }
     .payBtn{
      margin-top: 0;
      margin: auto;
      
     }
  }

  .product_wrapper {
    padding: 12px;
    border: 1px solid #d4d4d4;
    margin-bottom: 24px;
    border-radius: 8px;
    width: 100%;
  }
  
  .product_wrapper p {
    margin: 0;
  }

  .product_wrapper .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 12px;
  }
  
  .product_wrapper .desc {
    padding: 8px;
    background: #ebebeb;
    color: #4e4e4e;
  }
  @media screen and (max-width: 767.98px) {
    .main {
      margin: 40px 50px;
    }
    /* .first {
      width: 90%;
      margin: 25px auto;
      padding: 25px;
    } */
     .addBtn{
      margin: auto;

     }
     .all{
      display: flex;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      padding: 20px 45px ;
     }
     .payBtn{
      margin-top: 0;
      margin: auto;
     }
  }
  
  @media screen and (max-width: 576px) {
    .main {
      margin: 20px 20px;
    }
    .first {
      width: 95%;
      margin: 20px auto;
      padding: 20px;
    }
    .main::after {
      width: 130px;
    }
   
  }
  