*{
  font-family: 'Cairo';

}
.main {
    margin: 30px 60px;
    font-weight: 700;
    position: relative;
    padding-bottom: 10px;
}

.main::after {
    content: '';
    position: absolute;
    top: 100%; /* Positioning the line just below the h1 */
    width: 25%; /* Ensure the line spans the entire width of the h1 */
    height: 8px;
    background-color: #B39330;
    border-radius: 5px;
}

.ltr::after {
    left: 0; /* Align to the left in LTR mode */
    width: 42%;
}

.rtl::after {
    right: 0; /* Align to the right in RTL mode */
}

.upload {
    width: 80%;
    margin: 80px auto;
    
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    border: #B39330 1px solid;
    border-radius: 20px;
}
.previewImage {
    display: block;
    margin-top: 20px;
    width: 400px;
    height: 400px;
    border-radius: 50%; 
  }
.fileLabel {
    color: gray;
    cursor: pointer;
    padding: 15px;
    border: 1px solid black;
    border-radius: 8px;
    display: inline-block;
    margin-bottom: 10px;
}

.customFileLabel {
    display: inline-block;
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px black solid !important;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
}

.customFileLabel:hover {
    background-color: #ddd;
}

.uploadBtn {
    background-color: rgba(179, 147, 48, 1);
    color: white;
    padding: 10px 15px;
    border: 0;
    border-radius: 8px;
    text-decoration: none;
    display: inline-block;
    width: 50%;
    margin-top: 20px;
    cursor: pointer;
}

.fileInput {
    display: none; /* Hide the default file input */
}

.upload textarea {
    border: #717070 solid 1px !important;
    border-radius: 10px;
    width: 50%;
    height: 80px;
}


@media screen and (max-width: 576px) {
    .main::after{
        width: 260px;
    }
  
  }