* {
  font-family: 'Cairo', sans-serif;
  
}

.welcome {
  position: absolute;
  color: #FFFFFF;
  top: 120px;
  z-index: 99999;
  left: 150px;
  right: 150px;
  display: flex;
  width: 80%;
  z-index: 999; 
  font-weight: 700;
}
.layer{
  position: absolute;
  background-color: #0000008a;
  width: 100%;
  z-index: 9; 
  height: 100%;
}
.welcome2 {
  position: absolute;
  color: #FFFFFF;
  top: 210px;
  z-index: 99999;
  left: 150px;
  right: 150px;
  display: flex;
  width: 80%;
  z-index: 999; 
  font-weight: 700;

}


.buttonHome {
  background-color: white;
  color: rgba(179, 147, 48, 1);
  padding: 12px 40px;
  border-radius: 8px;
  transition: all 1s;
  text-decoration: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 400px;
  left: 150px;
  right: 150px;
  z-index: 999; 
  font-weight: 500;
  font-size: 22px;
  width: max-content;
}

.fixedSocial {
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.fixedSocial i {
  width: 55px;
  height: 55px;
  background-color: white;
  color: #B39330;
  border: 1px solid #B39330;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  z-index: 99;
  position: relative;
}

.buttonHome:hover {
  background-color: rgba(179, 147, 48, 1);
  color: white;
}

.contactHome {
  position: absolute;
  top: 200px;
  color: #B39330;
  font-weight: 600;
  
}

/* .line {
  position: absolute;
  top: 230px;
  height: 3px;
  width: 80px;
  background-color: #B39330;
} */

.icons {
  position: absolute;
  top: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 999; 
  gap: 4px;
}

.icons i {
  font-size: 32px;
  margin-right: 10px;
  color: #fff;
  padding-bottom: 15px;
  cursor: pointer;
}

.firstOpt {
  background-color: #E8EAF6 !important;
  color: #000000 !important;
  font-weight: 700;
  font-family: 'Cairo', sans-serif;
  border-top-right-radius: 10px !important;
}

span {
  display: none !important;
}

.secondOpt {
  background-color: #E8EAF6 !important;
  color: #000000 !important;
  font-weight: 700;
  font-family: 'Cairo', sans-serif;
  border-top-left-radius: 10px !important;
}

/* .TabPanel {
  width: 100% !important;
} */

.center-nav { 
  display: flex !important;
  justify-content: center !important;
}

.thewholeCards{

  background-color: red;
}


.cardHome {
  display: flex;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border: 0.5px solid #B39330;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(179, 147, 48, 0.5);
  padding: 20px;
  flex: 1;
  cursor: pointer;
}
.active{
  background-color: red !important;
}


.clients {
  margin: 60px 180px;
}

.comment_wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
}
.comment_wrapper label{
  font-size: 20px;
  font-weight: 600;
}
.comment_wrapper textarea{
  resize: none;
  min-height: 200px;
  border-radius: 8px;
  border: 2px rgb(222, 222, 222) solid;
  padding: 12px;
}

.comment_wrapper button{
  resize: none;
  border-radius: 8px;
  padding: 8px 16px;
  color: #fff;
  border: none;
  background-color: #B39330;
  transition: .3s ease-in all;
}

.comment_wrapper button:hover{
  background-color: #dfb73f;
}
.comment_wrapper textarea:focus{
  border-color: #dfb73f;
  outline: none;
}

.clientCard {
  border: 1px solid #B39330;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.25);

}

.name {
  font-weight: 700;
}

.tech {
  background-color: #B393300F;
  padding: 30px;
  text-align: center;
}

.icona {
  font-size: 3.5rem;
}

/* Media Queries for responsiveness */


@media (max-width: 1400px) {

  .icons {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 20px;
    justify-content: center;
    padding: 20px;
  }

}

@media (max-width: 1250px) {
  .buttonHome {
    left: 140px;
    right: 140px;
    width: 20%;
  }

}



@media (max-width: 992px) {
  .welcome {
    top: 80px;
    left: 100px;
    right: 100px;
  }
  .welcome2 {
    top: 160px;
    left: 100px;
    right: 100px;
  }
  .buttonHome {
    top: 300px;
    left: 80px;
    right: 80px;
    width: 30%;
  }
  .icons {
    left: 80px;
  }
  .contactHome{
    padding: 35px;
  }
  .line {
    left: 80px;
  }
  
  .clients {
    margin: 40px 100px;
  }
}


@media (max-width: 895px) {

  .welcome{
    left: 50px !important;
    right: 50px !important;
    line-height: 1.5em !important;
  }
  .welcome2{
    
    left: 50px !important;
    right: 50px !important;
    top: 150px !important ;
  }
  .buttonHome{
    left: 50px !important;
    right: 50px !important;
    top: 240px !important;
  }
  .icons{
    top: 310px !important;
  }
}
@media (max-width: 768px) {
  .welcome {
    top: 60px;
    left: 50px;
    right: 50px;
    line-height: 2.3rem;
  }
  .welcome2 {
    top: 120px;
    left: 50px;
    right: 50px;
  }
  .buttonHome {
    top: 200px;
    left: 50px;
    right: 50px;
    width: 50%;
  }
  .icons {
    left: 50px;
  }
 
  .clients {
    margin: 20px 50px;
  }
}

@media (max-width: 739px) {

  .icons{
    left: 160px !important;
    right: 160px !important;
  }
}


@media (max-width: 576px) {
  .welcome {
    top: 50px;
    left: 70px;
    right: 70px;
  }
  .welcome2 {
    width: 70%;
    top: 130px;
    left: 70px;
    right: 70px;
  }
  .buttonHome {
    top: 210px;
    left: 70px;
    right: 70px;
    width: 70%;
    padding: 8px 12px;
  }
  .icons {
    left: 50px;
    right: 50px;
    top: 290px;
  }
  .contactHome,
  .line {
    left: 20px;
    right: 20px;
  }
  .clients {
    margin: 50px 30px;
  }
}



@media (max-width: 767.98px) {
  .welcome {
    text-align: center;
    font-size: 17px;
  }
  .welcome2 {
    font-size: 17px;
    text-align: center !important;
    line-height: 20px;
    top: 87px !important;
  }
  .buttonHome {
    top: 130px !important;
    font-size: 18px;
    padding: 7px 22px !important;
    width: max-content;
    left: 50% !important;
    right: auto !important;
    transform: translateX(-50%);
  }
  .icons {
    top: 170px !important;
    width: 100%;
    display: flex;
    flex-direction: revert;
    justify-content: center;
    align-items: center;
    left: 0 !important;
    right: auto !important;
    gap: 4px;
  }
  .icons i {
    font-size: 22px;
  }
  .cardHome {
    height: 160px;
  }
}